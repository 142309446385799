<template>
    <div class="page-rooter-fff">
        <headerBar title="等级规则" left-arrow @click-left="newAppBack"/>
        <div class="page-content">
            <img src="https://img.chaolu.com.cn/ACT/membership/rule.png" alt="">
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import {newAppBack, initBack} from "@/lib/appMethod";
import headerBar from '@/components/app/headBar';

export default {
    data() {
        return {};
    },
    mixins: [userMixin],
    components: {headerBar},
    async created() {
        initBack(); //劫持后退
    },
    mounted() {
    },
    methods: {
        newAppBack
    },
};
</script>

<style lang="less" scoped>
    .page-content{
        img{
            display: block;
            width: 100%;
        }
    }
</style>
